import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-select'
import 'chartkick'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

window.$ = jQuery;

import './modal'
import './move'
